import React from "react";
import { Link } from 'react-router-dom';
const serving = () => {
    return (

        <div className="background-image">
            <div className="container">
                <div className="heading-serving1">
                    <h5>SERVING BETTER BETS</h5>
                    <h2>What Makes Our Experts Different</h2>
                </div>

                <div className="serving-box">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box-part">
                                <h4>PROVEN SUCCESS</h4>
                                <p> We offer full transparency where every single piece of advice has full sunlight to all users. There are no two sets of email  lists, whale plays, or a phony list of accomplishments. </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="box-part">
                                <h4>PASSION OVER PROFIT</h4>
                                <p> We offer full transparency where every single piece of advice has full sunlight to all users. There are no two sets of email  lists, whale plays, or a phony list of accomplishments. </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="box-part">
                                <h4>FULL TRANSPARENCY</h4>
                                <p> We offer full transparency where every single piece of advice has full sunlight to all users. There are no two sets of email  lists, whale plays, or a phony list of accomplishments. </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="box-part">
                                <h4>HERE TO SERVE</h4>
                                <p> We offer full transparency where every single piece of advice has full sunlight to all users. There are no two sets of email  lists, whale plays, or a phony list of accomplishments. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="learn-btn learnbtn1">
                    <Link to="#">CHECK OUT OUR OFFERINGS</Link>

                </div>
            </div>
        </div>

    )

}
export default serving;
