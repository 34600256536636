import React from 'react'
import { Link } from 'react-router-dom';
const Abouts = () => {
  return (
    <>

      <div class="main-banner">
        <div id="demo" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
            <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/main-banner1.jpg" alt="" class="d-block w-100" />
              <div className="banner-content">
                <h2>Bet Better with Insights from Honest Experts</h2>
                <div className="learn-btn">
                  <Link to="#">Learn More</Link>

                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img src="images/mainebanner2.jpg" alt="" class="d-block w-100" />
            </div>

          </div>



        </div>
      </div>

      {/* <div className="aBT">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <h2>What Makes Our Experts Different</h2>
        <p>
          Bagels With Locks (“BWL”) is serving better bets. But there are
          thousands of sports touts and almost as many critics of the tout
          industry. There are two major differences between this company and the
          grifters:
        </p>
        <ul>
          <li>
            <i className="fa fa-share" aria-hidden="true" /> Wade and Adam’s
            successes are documented by casinos including Circa, Hollywood
            Greektown, and FanDuel. Their contest wins over multiple seasons,
            and their win/loss figures cannot be faked.
          </li>
          <li>
            <i className="fa fa-share" aria-hidden="true" /> Wade and Adam have
            lucrative careers, serious passions, and families. These two factors
            combined to tell you that BWL is a hobby before it’s a business.
            Wade and Adam offer full transparency where every single piece of
            advice has full sunlight to all users – there is no two sets of
            email lists, whale plays, and phony list of accomplishments.
            Ultimately, BWL will teach you to make more educated decisions when
            you bet on your own. And that is the primary value BWL is offering.
            You do not need a fancy analytic system, to look at the stars, or to
            live in Las Vegas to place better bets.&nbsp;We&nbsp;got&nbsp;you.
          </li>
        </ul>
      </div>
      <div className="col-md-6">
        {<img src="images/abt.png" alt=""/>}
      </div>
    </div>
  </div>
</div> */}




    </>
  )
}

export default Abouts
