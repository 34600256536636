import React, { useState, useEffect } from 'react';
import config from '../config';
import './surviourGrid.css';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';

const SurviourGrid = () => {
    const [gridData, setGridData] = useState([]);

    useEffect(() => {
        fetch(`${config.baseUrl}/list-grid`)
            .then(response => response.json())
            .then(data => setGridData(data.data))
            .catch(error => console.error('Error fetching grid data:', error));
    }, []);
    const renderStars = (spread) => {
        const fullStars = Math.floor(spread);
        const halfStar = spread % 1 !== 0;
        const stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<i key={i} className="fa fa-star" aria-hidden="true"></i>);
        }

        if (halfStar) {
            stars.push(<i key={fullStars} className="fa fa-star-half" aria-hidden="true"></i>);
        }

        return stars;
    };
    return (
        <>
        <Header/>
        <div className="table-section">
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">EV</th>
                                <th scope="col">W%</th>
                                <th scope="col">P%</th>
                                <th scope="col">Team</th>
                                {gridData.length > 0 && gridData[0].week_info.map((week, index) => (
                                            <th key={index} scope="col">{week.week_originalname}</th>
                                        ))}
                                <th scope="col">Future Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gridData.map(grid => (
                                <tr key={grid.id}>
                                    <td>{grid.expected_value}</td>
                                    <td>{grid.win_percentage}%</td>
                                    <td>{grid.major_percentage}%</td>
                                    <td>{grid.team}</td>
                                    {grid.week_info.map((week, index) => (
                                        <td key={index} className={week.status === 1 ? 'h-green' : ''}>
                                            {week.opponent_originalname} <sub>{week.spread}</sub>
                                           
                                        </td>
                                    ))}
                                    <td>{renderStars(grid.future)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* <div className="spread">
                        <p>
                            <strong>Spread Key:</strong>
                            <span></span>
                        </p>
                        <ul>
                            <li>0</li>
                            <li>3</li>
                            <li>7</li>
                            <li>10</li>
                            <li>16+</li>
                        </ul>
                        <p />
                    </div> */}
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>
    )
}

export default SurviourGrid
