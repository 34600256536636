import React from 'react'

const Model = () => {
    return (
        <>
            <div className="modal" id="myModal">
                <div className="modal-dialog dialog1">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" />
                        </div>
                        {/* Modal body */}
                        <div className="modal-body">
                            <iframe
                                width="100%"
                                height={315}
                                src="https://www.youtube.com/embed/EQt2sq0_s64?si=BIe0LMjsHXSYD-CF"
                                title="YouTube video player"
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen=""
                            />
                        </div>
                        {/* Modal footer */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Model
