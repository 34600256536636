import React from 'react'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'

const FootballBetting = () => {
    return (
        <>
            <Header />
            <div style={{ margin: '30px 60px' }}>
                <h1 style={{ color: 'white' }}>Football Live Betting Strategy Point #1</h1>
                <p style={{ color: 'white' }}>The reason I have been so successful making live bets is because I trust my eyes. But there are a lot of things that I see, hopefully all at once, that leads me to pull the trigger. Here is one of the most important for football: who controls the line of scrimmage?
                    When most people watch, they see the highlight plays made by the stars. After all, that’s the fun part. But it is the big boys up front that win games. I put heavy emphasis on line play. In my view, football is not all that complicated. It certainly is not as complicated as some make it or want you to believe it is. It is a very simple game. If you can run the ball, and if you can protect the quarterback, you are going to score points. And if a team can’t run  the ball, or keep the quarterback upright, theya re going to have a hard time scoring. Many of the live bets I make are unders – either on a team total or a game total. And while there are some other things that go into my analysis, a big part of the analysis is line play. I try to target team totals around 17.5,  20.5, or 21.5.  I always want to put myself in a position where the offense needs to score multiple touchdowns to cover.
                    Note here, though: I try to stay away from betting unders when a team is featuring a top tier quarterback (Mahomes, Allen, etc.). These quarterbacks are so good they can hide many of a team’s deficiencies. </p>
            </div>
            <Footer />
        </>
    )
}

export default FootballBetting
