import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Homepage';
import NotFound from './404';
import Login from './User/Login';
import SignUp from './User/Register';
import ForgetPassword from './User/ForgetPassword';
import TermCondition from './Pages/TermCondition';
import FootballBetting from './Pages/FootballBetting';
import Privacy from './Pages/Privacy';
import SurviourGrid from './Pages/SurviourGrid';
import Membership from './Pages/Membership';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/sign-up' element={<SignUp />} />
        <Route exact path='/forget-password' element={<ForgetPassword />} />
        <Route exact path='/term-condition' element={<TermCondition />} />
        <Route exact path='/football-betting' element={<FootballBetting />} />
        <Route exact path='/privacy' element={<Privacy />} />
        <Route exact path='/surviour-grid' element={<SurviourGrid />} />
        <Route exact path='/membership' element={<Membership />} />
      </Routes>
    </Router>
  );
}

export default App;
