import React, { useState, useEffect, useMemo, useRef } from 'react';
import Slider from 'react-slick';
import ScoresMachup from './ScoresMachup';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import config from '../../config';

const CustomTabs = () => {
  const [headerFixed, setHeaderFixed] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Week1");
  const [selectedNcaa, setSelectedNcaa] = useState("Week1");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [oddsData, setOddsData] = useState([]);
  const [nbaScores, setNbaScores] = useState([]);
  const [nhlScores, setNhlScores] = useState([]);
  const [mlbScores, setMlbScores] = useState([]);
  const [mlsScores, setMlsScores] = useState([]);
  const [mmaScores, setMmaScores] = useState([]);
  const [ncaafScores, setNcaafScores] = useState([]);
  const [selectedDate, setSelectedDate] = useState('ALL');
  const sliderRef = useRef(null);
  useEffect(() => {
    setSelectedOption("WEEK1"); // Set "WEEK1" as the default value
    setSelectedNcaa("WEEK1")
  }, []);

  const fetchApiData = async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchApiData(`https://api.the-odds-api.com/v4/sports/americanfootball_nfl/odds?regions=us&markets=h2h,spreads,totals&oddsFormat=american&apiKey=${config.api}`, setOddsData);
    fetchApiData(`https://api.the-odds-api.com/v4/sports/basketball_nba/scores/?daysFrom=1&apiKey=${config.api}`, setNbaScores);
    fetchApiData(`https://api.the-odds-api.com/v4/sports/icehockey_nhl/odds/?apiKey=${config.api}&regions=us&markets=h2h,spreads&oddsFormat=american`, setNhlScores);
    fetchApiData(`https://api.the-odds-api.com/v4/sports/baseball_mlb/odds/?apiKey=${config.api}&regions=us&markets=h2h,spreads&oddsFormat=american`, setMlbScores);
    fetchApiData(`https://api.the-odds-api.com/v4/sports/soccer_usa_mls/odds/?apiKey=${config.api}&regions=us&markets=h2h,spreads&oddsFormat=american`, setMlsScores);
    fetchApiData(`https://api.the-odds-api.com/v4/sports/mma_mixed_martial_arts/odds?regions=us&markets=h2h&oddsFormat=american&apiKey=${config.api}`, setMmaScores);
    fetchApiData(`https://api.the-odds-api.com/v4/sports/americanfootball_ncaaf/odds/?apiKey=${config.api}&regions=us&markets=h2h,spreads&oddsFormat=american`, setNcaafScores);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setHeaderFixed(window.scrollY > 100);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Initialize table visibility to show only columns 1 through 5
    updateTableVisibility(0);  // Ensure the default columns are visible on mount
  }, []);
  useEffect(() => {
    // This effect simulates the click on the "Today" button to show it by default
    const defaultTab = document.getElementById('defaultOpen');
    if (defaultTab) {
      defaultTab.click();
    }
  }, []);

  const updateTableVisibility = (slideIndex) => {
    const startIndex = slideIndex;
    const endIndex = startIndex + 4; // Display 5 items (0-indexed)

    document.querySelectorAll('.table-column').forEach(column => {
      const columnIndex = parseInt(column.getAttribute('data-column-index'), 10);
      if (columnIndex >= startIndex + 1 && columnIndex <= endIndex + 1) {
        column.style.display = 'table-cell'; // Use 'table-cell' for table columns
      } else {
        column.style.display = 'none';
      }
    });
  };

  const handleAfterChange = (current) => {
    setCurrentSlide(current);
    updateTableVisibility(current);
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    afterChange: handleAfterChange,
    arrows: true,
  };

  const openCity = (evt, cityName) => {
    const tabcontent = document.getElementsByClassName('tabcontent');
    Array.from(tabcontent).forEach(content => content.style.display = 'none');

    const tablinks = document.getElementsByClassName('tablinks');
    Array.from(tablinks).forEach(link => link.className = link.className.replace(' active', ''));

    document.getElementById(cityName).style.display = 'block';
    evt.currentTarget.className += ' active';
  };

  const formatDate = (commence_time) => {
    const date = new Date(commence_time);
    const options = { weekday: 'short', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    return date.toLocaleDateString('en-US', options).replace(',', '');
  };
  const dateRanges = {
    "HF": { start: new Date("2024-08-01"), end: new Date("2024-08-01") },
    "P1": { start: new Date("2024-08-08"), end: new Date("2024-08-11") },
    "P2": { start: new Date("2024-08-12"), end: new Date("2024-08-15") },
    "WEEK1": { start: new Date("2024-09-05"), end: new Date("2024-09-09") },
    "WEEK2": { start: new Date("2024-09-12"), end: new Date("2024-09-16") },
    "WEEK3": { start: new Date("2024-09-19"), end: new Date("2024-09-23") },
    "WEEK4": { start: new Date("2024-09-26"), end: new Date("2024-09-30") },
    "WEEK5": { start: new Date("2024-10-03"), end: new Date("2024-10-07") },
    "WEEK6": { start: new Date("2024-10-10"), end: new Date("2024-10-14") },
    "WEEK7": { start: new Date("2024-10-17"), end: new Date("2024-10-21") },
    "WEEK8": { start: new Date("2024-10-24"), end: new Date("2024-10-28") },
    "WEEK9": { start: new Date("2024-10-31"), end: new Date("2024-11-04") },
    "WEEK10": { start: new Date("2024-11-07"), end: new Date("2024-11-11") },
    "WEEK11": { start: new Date("2024-11-14"), end: new Date("2024-11-18") },
    "WEEK12": { start: new Date("2024-11-21"), end: new Date("2024-11-25") },
    "WEEK13": { start: new Date("2024-11-28"), end: new Date("2024-12-02") },
    "WEEK14": { start: new Date("2024-12-05"), end: new Date("2024-12-09") },
    "WEEK15": { start: new Date("2024-12-12"), end: new Date("2024-12-16") },
    "WEEK16": { start: new Date("2024-12-19"), end: new Date("2024-12-23") },
    "WEEK17": { start: new Date("2024-12-25"), end: new Date("2024-12-30") },
    "WEEK18": { start: new Date("2025-01-05"), end: new Date("2025-01-05") },
  };
  const dateRangesNcaaf = {
    "WEEK1": { start: new Date("2024-08-24"), end: new Date("2024-09-02") },
    "WEEK2": { start: new Date("2024-09-06"), end: new Date("2024-09-07") },
    "WEEK3": { start: new Date("2024-09-12"), end: new Date("2024-09-14") },
    "WEEK4": { start: new Date("2024-09-19"), end: new Date("2024-09-22") },
    "WEEK5": { start: new Date("2024-09-26"), end: new Date("2024-09-28") },
    "WEEK6": { start: new Date("2024-10-03"), end: new Date("2024-10-05") },
    "WEEK7": { start: new Date("2024-10-08"), end: new Date("2024-10-12") },
    "WEEK8": { start: new Date("2024-10-15"), end: new Date("2024-10-19") },
    "WEEK9": { start: new Date("2024-10-22"), end: new Date("2024-11-27") },
    "WEEK10": { start: new Date("2024-10-29"), end: new Date("2024-11-02") },
    "WEEK11": { start: new Date("2024-11-05"), end: new Date("2024-11-09") },
    "WEEK12": { start: new Date("2024-11-12"), end: new Date("2024-11-16") },
    "WEEK13": { start: new Date("2024-11-19"), end: new Date("2024-12-23") },
    "WEEK14": { start: new Date("2024-11-26"), end: new Date("2024-11-30") }
  };

  const filteredNcaafScores = useMemo(() => {
    const selectedRange = dateRangesNcaaf[selectedNcaa];
    if (selectedRange) {
      return ncaafScores.filter((game) => {
        const gameDate = new Date(game.commence_time);
        return gameDate >= selectedRange.start && gameDate <= selectedRange.end;
      });
    }
    return ncaafScores;
  }, [selectedNcaa, ncaafScores]);

  const filteredOddsData = useMemo(() => {
    const selectedRange = dateRanges[selectedOption];
    if (selectedRange) {
      return oddsData.filter((game) => {
        const gameDate = new Date(game.commence_time);
        return gameDate >= selectedRange.start && gameDate <= selectedRange.end;
      });
    }
    return oddsData;
  }, [selectedOption, oddsData]);
  const filteredNbaScores = useMemo(() => {
    return nbaScores.filter(game => game.bookmakers > 0);
  }, [nbaScores]);



  return (
    <>
      <section className="custumtab tab-part">
        <div className="container-fluid">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
              <button class="nav-link active" id="score-tab" data-bs-toggle="tab" data-bs-target="#score" type="button"
                role="tab" aria-controls="score" aria-selected="false">Scores & Matchups</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="odd-tab" data-bs-toggle="tab" data-bs-target="#odd"
                type="button" role="tab" aria-controls="odd" aria-selected="true">ODD</button>
            </li>

          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" id="odd" role="tabpanel" aria-labelledby="odd-tab">
              <div class="custumtab">
                <div className="tab">
                  {/* <button className="tablinks" onClick={(e) => openCity(e, 'London')} >
              Today
            </button> */}
                  <button className="tablinks" onClick={(e) => openCity(e, 'Paris')} id="defaultOpen">NFL</button>
                  <button className="tablinks" onClick={(e) => openCity(e, 'American')}>NCAAF</button>
                  <button className="tablinks" onClick={(e) => openCity(e, 'Tokyo')}>NBA</button>
                  <button className="tablinks" onClick={(e) => openCity(e, 'Tokyo1')}>NHL</button>
                  <button className="tablinks" onClick={(e) => openCity(e, 'Tokyo2')}>MLB</button>
                  <button className="tablinks" onClick={(e) => openCity(e, 'Tokyo3')}>MLS</button>
                  {/* <button className="tablinks" onClick={(e) => openCity(e, 'Tokyo4')}>GOLF</button> */}
                  <button className="tablinks" onClick={(e) => openCity(e, 'Tokyo5')}>UFC</button>
                </div>
              </div>
              <div id="Paris" className="tabcontent">
                <div class="row">
                  <div class="col-md-6">
                    <div>
                      <button type="button" className="odd">
                        Sports Betting Odds Today
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="select-box">
                      <select
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        {/* <option value="All">All</option> */}
                        <option value="HF">HF</option>
                        <option value="P1">P1</option>
                        <option value="P2">P2</option>
                        <option value="P3">P3</option>
                        <option value="WEEK1">WEEK1</option>
                        <option value="WEEK2">WEEK2</option>
                        <option value="WEEK3">WEEK3</option>
                        <option value="WEEK4">WEEK4</option>
                        <option value="WEEK5">WEEK5</option>
                        <option value="WEEK6">WEEK6</option>
                        <option value="WEEK7">WEEK7</option>
                        <option value="WEEK8">WEEK8</option>
                        <option value="WEEK9">WEEK9</option>
                        <option value="WEEK10">WEEK10</option>
                        <option value="WEEK11">WEEK11</option>
                        <option value="WEEK12">WEEK12</option>
                        <option value="WEEK13">WEEK13</option>
                        <option value="WEEK14">WEEK14</option>
                        <option value="WEEK15">WEEK15</option>
                        <option value="WEEK16">WEEK16</option>
                        <option value="WEEK17">WEEK17</option>
                        <option value="WEEK18">WEEK18</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div className="table-image12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="left-top">
                        <div className="images-part">
                          <img alt="" src="images/NFL.png" />
                        </div>
                        <div className="content-part">
                          <h4>NFL</h4>
                          <p>HOF</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="right-top">
                        <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                            <img alt="" src="images/original-DraftKings.png" />
                          </div>
                          <div className="item" data-index={2}>
                            <img alt="" src="images/original-Fanduel.png" />
                          </div>
                          <div className="item" data-index={3}>
                            <img alt="" src="images/original-BetMGM.png" />
                          </div>
                          <div className="item" data-index={4}>
                            <img alt="" src="images/original-CaesarsSportsbook.png" />
                          </div>
                          <div className="item" data-index={5}>
                            <img alt="" src="images/original-BetRivers.png" />
                          </div>
                          <div className="item" data-index={6}>
                            <img alt="" src="images/betOnline.png" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                {filteredOddsData.map((game, index) => (
                  <div key={index}>
                    <div class="rating-up">
                      <div className="rating">
                        <div className="table11">
                          <h5>
                            <i className="fa fa-star-o" aria-hidden="true" />{formatDate(game.commence_time)}
                          </h5>
                        </div>
                      </div>

                      <div className="new-table">
                        <div className="table23">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="table-blk">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{game.home_team}</p>
                                      </td>
                                      {/* <td>12%</td> */}
                                      <td>
                                        <p>
                                          {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                            bookmaker.key === 'fanduel' &&
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team && (

                                                <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </strong>

                                              )
                                            ))
                                          ))}
                                        </p>
                                        <p>FanDuel</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>{game.away_team}</p>
                                      </td>
                                      {/* <td>88%</td> */}
                                      <td>
                                        <p>{game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </strong>

                                            )
                                          ))
                                        ))}
                                        </p>
                                        <p>BetMGM</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' ? (
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team ? (

                                                <button key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </button>

                                              ) : null
                                            ))
                                          ) : null
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>


                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div id="American" className="tabcontent">
                <div class="row">
                  <div class="col-md-6">
                    <div>
                      <button type="button" className="odd">
                        ODD
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="select-box">
                      <select
                        value={selectedNcaa}
                        onChange={(e) => setSelectedNcaa(e.target.value)}
                      >
                        {/* <option value="All">All</option> */}
                        <option value="WEEK1">WEEK1</option>
                        <option value="WEEK2">WEEK2</option>
                        <option value="WEEK3">WEEK3</option>
                        <option value="WEEK4">WEEK4</option>
                        <option value="WEEK5">WEEK5</option>
                        <option value="WEEK6">WEEK6</option>
                        <option value="WEEK7">WEEK7</option>
                        <option value="WEEK8">WEEK8</option>
                        <option value="WEEK9">WEEK9</option>
                        <option value="WEEK10">WEEK10</option>
                        <option value="WEEK11">WEEK11</option>
                        <option value="WEEK12">WEEK12</option>
                        <option value="WEEK13">WEEK13</option>
                        <option value="WEEK14">WEEK14</option>
                      </select>
                    </div>
                  </div>
                </div>





                <div className="table-image12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="left-top">
                        <div className="images-part">
                          <img alt="" src="images/NCAAF.png" />
                        </div>
                        <div className="content-part">
                          <h4>NCAAF</h4>
                          <p>HOF</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="right-top">
                        <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                            <img alt="" src="images/original-DraftKings.png" />
                          </div>
                          <div className="item" data-index={2}>
                            <img alt="" src="images/original-Fanduel.png" />
                          </div>
                          <div className="item" data-index={3}>
                            <img alt="" src="images/original-BetMGM.png" />
                          </div>
                          <div className="item" data-index={4}>
                            <img alt="" src="images/original-CaesarsSportsbook.png" />
                          </div>
                          <div className="item" data-index={5}>
                            <img alt="" src="images/original-BetRivers.png" />
                          </div>
                          <div className="item" data-index={6}>
                            <img alt="" src="images/betOnline.png" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                {filteredNcaafScores.map((game, index) => (
                  <div key={index}>
                    <div class="rating-up">
                      <div className="rating">
                        <div className="table11">
                          <h5>
                            <i className="fa fa-star-o" aria-hidden="true" />{formatDate(game.commence_time)}
                          </h5>
                        </div>
                      </div>

                      <div className="new-table">
                        <div className="table23">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="table-blk">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{game.home_team}</p>
                                      </td>
                                      {/* <td>12%</td> */}
                                      <td>
                                        <p>
                                          {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                            bookmaker.key === 'fanduel' &&
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team && (

                                                <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </strong>

                                              )
                                            ))
                                          ))}
                                        </p>
                                        <p>FanDuel</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>{game.away_team}</p>
                                      </td>
                                      {/* <td>88%</td> */}
                                      <td>
                                        <p>{game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </strong>

                                            )
                                          ))
                                        ))}
                                        </p>
                                        <p>BetMGM</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' ? (
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team ? (

                                                <button key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </button>

                                              ) : null
                                            ))
                                          ) : null
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>


                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div id="Tokyo" className="tabcontent">
                <div>
                  <button type="button" className="odd">
                    ODD
                  </button>
                </div>
                <div className="table-image12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="left-top">
                        <div className="images-part">
                          <img alt="" src="images/NBA.png" />
                        </div>
                        <div className="content-part">
                          <h4>NBA</h4>
                          <p>HOF</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="right-top">
                        <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                            <img alt="" src="images/original-DraftKings.png" />
                          </div>
                          <div className="item" data-index={2}>
                            <img alt="" src="images/original-Fanduel.png" />
                          </div>
                          <div className="item" data-index={3}>
                            <img alt="" src="images/original-BetMGM.png" />
                          </div>
                          <div className="item" data-index={4}>
                            <img alt="" src="images/original-CaesarsSportsbook.png" />
                          </div>
                          <div className="item" data-index={5}>
                            <img alt="" src="images/original-BetRivers.png" />
                          </div>
                          <div className="item" data-index={6}>
                            <img alt="" src="images/betOnline.png" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                {filteredNbaScores.length > 0 ? filteredNbaScores.slice(0, 20).map((game, index) => (
                  <div key={index}>
                    <div class="rating-up">
                      <div className="rating">
                        <div className="table11">
                          <h5>
                            <i className="fa fa-star-o" aria-hidden="true" />{formatDate(game.commence_time)}
                          </h5>
                        </div>
                      </div>

                      <div className="new-table">
                        <div className="table23">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="table-blk">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{game.home_team}</p>
                                      </td>
                                      {/* <td>12%</td> */}
                                      <td>
                                        <p>
                                          {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                            bookmaker.key === 'fanduel' &&
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team && (

                                                <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </strong>

                                              )
                                            ))
                                          ))}
                                        </p>
                                        <p>FanDuel</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>{game.away_team}</p>
                                      </td>
                                      {/* <td>88%</td> */}
                                      <td>
                                        <p>{game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </strong>

                                            )
                                          ))
                                        ))}
                                        </p>
                                        <p>BetMGM</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' ? (
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team ? (

                                                <button key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </button>

                                              ) : null
                                            ))
                                          ) : null
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>


                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )) : <p>Loading Nba data...</p>}
              </div>
              <div id="Tokyo1" className="tabcontent">
                <div>
                  <button type="button" className="odd">
                    ODD
                  </button>
                </div>
                <div className="table-image12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="left-top">
                        <div className="images-part">
                          <img alt="" src="images/NHL.png" />
                        </div>
                        <div className="content-part">
                          <h4>NHL</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="right-top">
                        <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                            <img alt="" src="images/original-DraftKings.png" />
                          </div>
                          <div className="item" data-index={2}>
                            <img alt="" src="images/original-Fanduel.png" />
                          </div>
                          <div className="item" data-index={3}>
                            <img alt="" src="images/original-BetMGM.png" />
                          </div>
                          <div className="item" data-index={4}>
                            <img alt="" src="images/original-CaesarsSportsbook.png" />
                          </div>
                          <div className="item" data-index={5}>
                            <img alt="" src="images/original-BetRivers.png" />
                          </div>
                          <div className="item" data-index={6}>
                            <img alt="" src="images/betOnline.png" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                {nhlScores.slice(0, 20).map((game, index) => (
                  <div key={index}>
                    <div class="rating-up">
                      <div className="rating">
                        <div className="table11">
                          <h5>
                            <i className="fa fa-star-o" aria-hidden="true" />{formatDate(game.commence_time)}
                          </h5>
                        </div>
                      </div>

                      <div className="new-table">
                        <div className="table23">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="table-blk">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{game.home_team}</p>
                                      </td>
                                      {/* <td>12%</td> */}
                                      <td>
                                        <p>
                                          {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                            bookmaker.key === 'fanduel' &&
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team && (

                                                <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </strong>

                                              )
                                            ))
                                          ))}
                                        </p>
                                        <p>FanDuel</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>{game.away_team}</p>
                                      </td>
                                      {/* <td>88%</td> */}
                                      <td>
                                        <p>{game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </strong>

                                            )
                                          ))
                                        ))}
                                        </p>
                                        <p>BetMGM</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' ? (
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team ? (

                                                <button key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </button>

                                              ) : null
                                            ))
                                          ) : null
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>


                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>
              <div id="Tokyo2" className="tabcontent">
                <div>
                  <button type="button" className="odd">
                    ODD
                  </button>
                </div>
                <div className="table-image12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="left-top">
                        <div className="images-part">
                          <img alt="" src="images/MLB.png" />
                        </div>
                        <div className="content-part">
                          <h4>MLB</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="right-top">
                        <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                            <img alt="" src="images/original-DraftKings.png" />
                          </div>
                          <div className="item" data-index={2}>
                            <img alt="" src="images/original-Fanduel.png" />
                          </div>
                          <div className="item" data-index={3}>
                            <img alt="" src="images/original-BetMGM.png" />
                          </div>
                          <div className="item" data-index={4}>
                            <img alt="" src="images/original-CaesarsSportsbook.png" />
                          </div>
                          <div className="item" data-index={5}>
                            <img alt="" src="images/original-BetRivers.png" />
                          </div>
                          <div className="item" data-index={6}>
                            <img alt="" src="images/betOnline.png" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                {mlbScores.slice(0, 20).map((game, index) => (
                  <div key={index}>
                    <div class="rating-up">
                      <div className="rating">
                        <div className="table11">
                          <h5>
                            <i className="fa fa-star-o" aria-hidden="true" />{formatDate(game.commence_time)}
                          </h5>
                        </div>
                      </div>

                      <div className="new-table">
                        <div className="table23">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="table-blk">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{game.home_team}</p>
                                      </td>
                                      {/* <td>12%</td> */}
                                      <td>
                                        <p>
                                          {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                            bookmaker.key === 'fanduel' &&
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team && (

                                                <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </strong>

                                              )
                                            ))
                                          ))}
                                        </p>
                                        <p>FanDuel</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>{game.away_team}</p>
                                      </td>
                                      {/* <td>88%</td> */}
                                      <td>
                                        <p>{game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </strong>

                                            )
                                          ))
                                        ))}
                                        </p>
                                        <p>BetMGM</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' ? (
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team ? (

                                                <button key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </button>

                                              ) : null
                                            ))
                                          ) : null
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>


                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div id="Tokyo3" className="tabcontent">
                <div>
                  <button type="button" className="odd">
                    ODD
                  </button>
                </div>
                <div className="table-image12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="left-top">
                        <div className="images-part">
                          <img alt="" src="images/MLS.png" />
                        </div>
                        <div className="content-part">
                          <h4>MLS</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="right-top">
                        <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                            <img alt="" src="images/original-DraftKings.png" />
                          </div>
                          <div className="item" data-index={2}>
                            <img alt="" src="images/original-Fanduel.png" />
                          </div>
                          <div className="item" data-index={3}>
                            <img alt="" src="images/original-BetMGM.png" />
                          </div>
                          <div className="item" data-index={4}>
                            <img alt="" src="images/original-CaesarsSportsbook.png" />
                          </div>
                          <div className="item" data-index={5}>
                            <img alt="" src="images/original-BetRivers.png" />
                          </div>
                          <div className="item" data-index={6}>
                            <img alt="" src="images/betOnline.png" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                {mlsScores.slice(0, 20).map((game, index) => (
                  <div key={index}>
                    <div class="rating-up">
                      <div className="rating">
                        <div className="table11">
                          <h5>
                            <i className="fa fa-star-o" aria-hidden="true" />{formatDate(game.commence_time)}
                          </h5>
                        </div>
                      </div>

                      <div className="new-table">
                        <div className="table23">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="table-blk">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{game.home_team}</p>
                                      </td>
                                      {/* <td>12%</td> */}
                                      <td>
                                        <p>
                                          {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                            bookmaker.key === 'fanduel' &&
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team && (

                                                <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </strong>

                                              )
                                            ))
                                          ))}
                                        </p>
                                        <p>FanDuel</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>{game.away_team}</p>
                                      </td>
                                      {/* <td>88%</td> */}
                                      <td>
                                        <p>{game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </strong>

                                            )
                                          ))
                                        ))}
                                        </p>
                                        <p>BetMGM</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' ? (
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team ? (

                                                <button key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </button>

                                              ) : null
                                            ))
                                          ) : null
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>


                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div id="Tokyo5" className="tabcontent">
                <div>
                  <button type="button" className="odd">
                    ODD
                  </button>
                </div>
                <div className="table-image12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="left-top">
                        <div className="images-part">
                          <img alt="" src="images/UFC.png" />
                        </div>
                        <div className="content-part">
                          <h4>UFC</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="right-top">
                        <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                            <img alt="" src="images/original-DraftKings.png" />
                          </div>
                          <div className="item" data-index={2}>
                            <img alt="" src="images/original-Fanduel.png" />
                          </div>
                          <div className="item" data-index={3}>
                            <img alt="" src="images/original-BetMGM.png" />
                          </div>
                          <div className="item" data-index={4}>
                            <img alt="" src="images/original-CaesarsSportsbook.png" />
                          </div>
                          <div className="item" data-index={5}>
                            <img alt="" src="images/original-BetRivers.png" />
                          </div>
                          <div className="item" data-index={6}>
                            <img alt="" src="images/betOnline.png" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                {mmaScores.slice(0, 20).map((game, index) => (
                  <div key={index}>
                    <div class="rating-up">
                      <div className="rating">
                        <div className="table11">
                          <h5>
                            <i className="fa fa-star-o" aria-hidden="true" />{formatDate(game.commence_time)}
                          </h5>
                        </div>
                      </div>

                      <div className="new-table">
                        <div className="table23">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="table-blk">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{game.home_team}</p>
                                      </td>
                                      {/* <td>12%</td> */}
                                      <td>
                                        <p>
                                          {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                            bookmaker.key === 'fanduel' &&
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team && (

                                                <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </strong>

                                              )
                                            ))
                                          ))}
                                        </p>
                                        <p>FanDuel</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>{game.away_team}</p>
                                      </td>
                                      {/* <td>88%</td> */}
                                      <td>
                                        <p>{game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <strong key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </strong>

                                            )
                                          ))
                                        ))}
                                        </p>
                                        <p>BetMGM</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' ? (
                                            bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                              outcome.name === game.home_team ? (

                                                <button key={outcomeIndex} type="button" className="buttonnew1">
                                                  <span>{outcome.price ? outcome.price : '-'}</span>
                                                </button>

                                              ) : null
                                            ))
                                          ) : null
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.home_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>


                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-blk1">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>

                                      <td className="table-column" data-column-index={1}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'draftkings' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={2}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'fanduel' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={3}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betmgm' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={4}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'williamhill_us' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={5}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betrivers' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                      <td className="table-column" data-column-index={6}>
                                        {game.bookmakers.map((bookmaker, bookmakerIndex) => (
                                          bookmaker.key === 'betonlineag' &&
                                          bookmaker.markets[0].outcomes.map((outcome, outcomeIndex) => (
                                            outcome.name === game.away_team && (

                                              <button key={outcomeIndex} type="button" className="buttonnew1">
                                                <span>{outcome.price ? outcome.price : '-'}</span>
                                              </button>

                                            )
                                          ))
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div class="tab-pane fade  show active" id="score" role="tabpanel" aria-labelledby="score-tab">
              <ScoresMachup oddsData={oddsData} ncaafScores={ncaafScores} />
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default CustomTabs;