import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'; 
import 'owl.carousel/dist/assets/owl.theme.default.css'; 


const ProfileTabs = () => {
    const [popupImage, setPopupImage] = useState(null);

    const openPopup = (imageSrc) => {
        setPopupImage(imageSrc);
    };

    const closePopup = () => {
        setPopupImage(null);
    };

    return (
        <>
            <section className="slider-tabs">
                <div className="container">
                <div className="Meet">
                    <h2 >Meet Your Winning Team</h2>
                </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="profile-box">
                                <div className="profile-part">
                                    <div className="row">
                                        <div className="col-md-6">
                                         <div className="person-name1"> 
                                        <img src="images/adampic.png" alt=""/>
                                        </div>  
                                        </div>
                                        <div className="col-md-6">
                                         <div className="name-part">  
                                        <img src="images/Adamname.png" alt=""/>
                                        </div> 
                                        <ul className="lsit-part">
                                            <li><i class="fa fa-check" aria-hidden="true"></i> Live-Betting        
                                            </li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i> Futures Markets        
                                            </li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i> Hedge Bets       
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-content">
                                    <p>Adam Greenberg is a CEO, CFO, and serial entrepreneur who has started and led multi-million-dollar companies. His ventures are diverse, ranging from medical products and selling on Amazon to coal mining, which speaks to his ability to quickly learn and adapt to complex new information. Adam's skill set is what has made him a profitable bettor in multiple sports, including consistently winning in live-betting and futures markets. Adam will help you learn live-betting and the futures market. His goal is to teach you how to better understand quickly changing markets and position yourself for huge scores and hedging opportunities.</p>
                                </div>
                                <div className='progile-img13'>
                                                <div className="left-profile profile11">
                                                    <Link to="#">
                                                        <img
                                                            src="images/proffile33.jpg"
                                                            alt="winimage3"
                                                            onClick={() => openPopup('images/proffile33.jpg')}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                                <div className="profilemiddle">
                                                <div className="left-profile profile22">
                                                    <img
                                                        src="images/profile1.jpg"
                                                        alt="winimage1"
                                                        onClick={() => openPopup('images/profile1.jpg')}
                                                    />
                                                </div>
                                                </div>
                                                <div className="profile-last">
                                                <div className="left-profile profile33">
                                                    <Link to="#">
                                                        <img
                                                            src="images/profile22.jpg"
                                                            alt="winimage2"
                                                            onClick={() => openPopup('images/profile22.jpg')}
                                                        />
                                                    </Link>
                                                </div>
                                                </div>
                                           
                                
                            </div>
                     
                        </div>
                        <div className="col-md-6">
                            <div className="profile-box">
                                <div className="profile-part wade-part">
                                    <div className="row">
                                        
                                        <div className="col-md-6" >
                                         <div className="name-part">  
                                        <img src="images/wadename.png" alt=""/>
                                        </div> 
                                        <ul className="lsit-part">
                                            <li><i class="fa fa-check" aria-hidden="true"></i> CONTESTS        
                                            </li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i> PROFITABLE
                                            PICKS        
                                            </li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i> FUTURES
                                            MARKET      
                                            </li>
                                        </ul>
                                        </div>
                                        <div className="col-md-6">
                                         <div className="person-name1"> 
                                        <img src="images/wadepic.png" alt=""/>
                                        </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-content">
                                    <p>Wade Fink is a prominent attorney in Michigan, specializing in criminal defense work and reversing wrongful convictions. He has represented numerous sports stars in various capacities. His legal work and analysis has been featured in print publications like the New Yorker, New York Times, Washington Post, and others, as well as on television, including on HBO, Netflix, CNN, and others. His legal skills translated well to sports betting in his ability to synthesize lots of information, tune out the noise, and make profitable picks in the NFL, College Football, and various futures markets for five straight years. Most recently, Wade won $500,000.00 in Hollywood Casino’s Survivor Contest, which was on the heels of win tens of thousands in the Circa Millions pick ‘em contest. Wade will help you in your contests and football picks. His goal is to teach you how to put yourself in the best position possible for big scores.</p>
                                </div>
                                <div className='progile-img13'>
                                                <div className="left-profile profilewade">
                                                    <Link to="#">
                                                        <img
                                                            src="images/wadewin1.jpg"
                                                            alt="winimage3"
                                                            onClick={() => openPopup('images/wadewin1.jpg')}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                               
                                               
                                           
                                
                            </div>
                     
                        </div>
                        
                     
                    </div>
                </div>
            </section>
            {popupImage && (
                <div id="imagePopup" className="popup" style={popupStyles}>
                    <span className="close" onClick={closePopup} style={closeButtonStyles}>&times;</span>
                    <img className="popup-content" id="popupImage" src={popupImage} alt="Popup" style={popupImageStyles} />
                </div>
            )}
        </>
    );
}

const popupStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.8)',
};

const closeButtonStyles = {
    position: 'absolute',
    top: '20px',
    right: '35px',
    color: '#f1f1f1',
    fontSize: '40px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
};

const popupImageStyles = {
    maxWidth: '90%',
    maxHeight: '90%',
};

export default ProfileTabs;
