import React from 'react';
import { Link } from 'react-router-dom';
import './membership.css';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';

const Membership = () => {
    return (
        <>
        <Header/>
            <section className="membership-part">
                <div className="container">
                    <div className="membership-heading">
                        <h2>Our Membership Menu</h2>
                        <h5>SIGN UP FOR FREE AND EXPLORE OUR PREMIUM OPTIONS</h5>
                        <p>
                            Create your free account with just your email, and instantly access
                            exclusive tips, valuable resources, and entry into our complimentary
                            Survivor Pool contests. Ready for more? Upgrade to unlock deeper
                            insights and bigger opportunities.
                        </p>
                        <div className="membership-box">
                            <input type="text" placeholder="Enter Your Email" />
                            <input type="submit" defaultValue="Sign Up Now" />
                        </div>
                    </div>
                </div>
                <div className="membership-menu">
                    <div className="container">
                        <div className="menu-member">
                            <div className="menu-part1">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="menu-images">
                                            <img src="images/bagelsmenu1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="surviour-right">
                                            <h5>TIER 1</h5>
                                            <h6>
                                                <i className="fa fa-bolt" aria-hidden="true" />
                                                Early Bird Price
                                            </h6>
                                            <h4>$59.99 Monthly</h4>
                                            <div className="meber-bagel1">
                                                <div className="left-bagel1">
                                                    <h3>BAGEL</h3>
                                                    <div className="dot-part" />
                                                    <h3>
                                                        <s>$89.99 Monthly</s>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="surviour-list1">
                                                <ul>
                                                    <li>Weekly Survivor advice with podcast</li>
                                                    <li>Access to Wade’s Survivor Grid</li>
                                                    <li>
                                                        Weekly NFL Circa Contest picks (5 picks per week) with
                                                        podcast
                                                    </li>
                                                </ul>
                                                <div className="Surviour-btn">
                                                    <Link href="#">Order Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="menu-part1">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="menu-images">
                                            <img src="images/bagelsmenu2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="surviour-right">
                                            <h5>TIER 2</h5>
                                            <h6>
                                                <i className="fa fa-bolt" aria-hidden="true" />
                                                Early Bird Price
                                            </h6>
                                            <h4>$109.99 Monthly</h4>
                                            <div className="meber-bagel1">
                                                <div className="left-bagel1">
                                                    <h3>BAGEL WITH SPREAD</h3>
                                                    <div className="dot-part" />
                                                    <h3>
                                                        <s>$149.99 Monthly</s>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="surviour-list1">
                                                <p>Everything from Tier 1, plus:</p>
                                                <ul>
                                                    <li>Full NFL Access</li>
                                                    <li>Complete College Football Access</li>
                                                </ul>
                                                <div className="Surviour-btn">
                                                    <Link href="#">Order Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="menu-part1">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="menu-images">
                                            <img src="images/bagelsmenu3.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="surviour-right">
                                            <h5>TIER 3</h5>
                                            <h6>
                                                <i className="fa fa-bolt" aria-hidden="true" />
                                                Early Bird Price
                                            </h6>
                                            <h4>$109.99 Monthly</h4>
                                            <div className="meber-bagel1">
                                                <div className="left-bagel1">
                                                    <h3>BAGEL, SPREAD, &amp; LOCKS</h3>
                                                    <div className="dot-part" />
                                                    <h3>
                                                        <s>$199.99 Monthly</s>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="surviour-list1">
                                                <p>Everything from Tier 1 &amp; 2, plus:</p>
                                                <ul>
                                                    <li>Wagers on all sports, including Futures</li>
                                                    <li>
                                                        Access to picks on NFL, NCAAF, NCAAB, NBA, Golf,Men's
                                                        &amp; Women's Tennis, MLB
                                                    </li>
                                                    <li>
                                                        Transparent live betting with access to Adam's actual bet
                                                        slips
                                                    </li>
                                                    <li>
                                                        Video recaps breaking down the thought process behind each
                                                        wager
                                                    </li>
                                                    <li>Weekly Q&amp;A streams with Adam</li>
                                                    <li>Year-round content and winning strategies</li>
                                                </ul>
                                                <div className="Surviour-btn">
                                                    <Link href="#">Order Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>

        </>
    )
}

export default Membership
