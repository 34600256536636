import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
  <section className="footer">

  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className='footer-left'>
          <h4>Follow Us for More Tips</h4>
          <ul className="list11">
            <li><img src="images/twitterx.png" alt="" className='twitter1'/></li>
            <li><img src="images/instgram.png" alt="" className='twitter1'/></li>
            <li><img src="images/yotube.png" alt=""/></li>
          </ul>
        </div>
      </div>
      <div className="col-md-4">
        <div className='footerlogo'>
          <img src="images/footerlogo.png" alt=""/>
        </div>
      </div>
      <div className="col-md-4">
      <div className="list-menu">
        <ul>
        <li>
            <Link to="#">Contact Us</Link>
          </li>
          <li>
            <Link to="#">Sign Up Now</Link>
          </li>
          <li>
            <Link to="/term-condition">Term Condition</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
      </div>
    </div>
   
  </div>
  <div className="copyright1">
    <div className="container">
      <p>
        Copyright © 2024 Bagelswithlocks. All rights reserved. - bagelswithlocks
        is the home of daily sports betting updates and free picks.
      </p>
    </div>
  </div>
</section>

    </>
  )
}

export default Footer
