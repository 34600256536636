import React from 'react';
import './homepage.css'
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import ProfileTabs from '../Components/Homepage/ProfileTabs';
import VideoShow from '../Components/Homepage/VideoShow';
import CustomTabs from '../Components/Homepage/CustomTabs';
import Abouts from '../Components/Homepage/Abouts';
import Serving from '../Components/Homepage/serving';
import Model from '../Components/Homepage/Model';

const Homepage = () => {
  return (
    <>
    <Header/>
    <Abouts/>
    <Serving/>
    <ProfileTabs/>
    <VideoShow/>
    <CustomTabs/>
    <Footer/>
    <Model/>
    </>
  )
}

export default Homepage
