import React, { useState, useEffect } from 'react';
import config from '../../config';
import { Link } from 'react-router-dom';

const Header = () => {
  const [firstName, setFirstName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [membershipDropdownOpen, setMembershipDropdownOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedFirstName = localStorage.getItem('firstName');
    if (token && storedFirstName) {
      setFirstName(storedFirstName);
    }
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.baseUrl}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem('token');
        localStorage.removeItem('firstName');
        setFirstName('');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMembershipDropdown = () => {
    setMembershipDropdownOpen(true);
  };
  return (
    <>
      <section className="header-part1">
        <div className="header-part">
          <div className="container">
           <p>Enter our <strong>FREE</strong> NFL Survivor Pool and <strong>WIN $4,500 CASH </strong><i class="fa fa-caret-right" aria-hidden="true"></i>
           </p>
          </div>
        </div>
        <div className="main-header">
          <div className="container">
            <div className="main-2">
              <Link to="/" className="logo-left">
                <img src="images/logonew.png" alt="" />
              </Link>
              <div className="menu-center">
                <ul>
                 
                  <li className="dropdown submenu">
                    <Link to="/membership" onMouseEnter={toggleMembershipDropdown}>Membership</Link>
                    {membershipDropdownOpen && (
                      <ul className="dropdown-menu ">
                        <li>
                          <Link className="dropdown-text" to="#">Circa contest picks</Link>
                        </li>
                        <li>
                          <Link className="dropdown-text" to="/surviour-grid">Survivor Grid</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <Link to="">Survivor Pool</Link>
                  </li>
                  <li>
                    <Link to="#">Adam Bakery</Link>
                  </li>
                  <li>
                    <Link to="#">Wade Kitchen</Link>
                  </li>
                  <li>
                    <Link to="#">About Us</Link>
                  </li>
                </ul>
              </div>
              <div className="sign-right">
              <div className="search-box1">
                  <img src="images/searchicon.png" alt="" />
                </div> 
              <div className="account1">
                  <img src="images/account.png" alt="" />
                </div>
                <div className="cart-img">
                  <img src="images/cart-icon.png" alt="" />
                </div>
                
                <div className="login-part">
                  {/* <div className="login-btn">
                    {firstName ? (
                      <div className="custom-dropdown">
                        <button className="btn btn-secondary" onClick={toggleDropdown}>
                          {firstName}
                        </button>
                        {dropdownOpen && (
                          <div className="dropdown-content">
                            <Link className="dropdown-item" to="#" onClick={handleLogout}>Logout</Link>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className=''>
                        <Link to="/login">LOGIN</Link>
                        <Link className="Signup" to="/sign-up">SIGN UP</Link>
                  
                
                      </div>
                      
                    )}
                  </div> */}
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Header
